<template>
  <PVSidebar
    v-model:visible="visible"
    class="!w-[30%]"
    :header="$t('course_page.distribute_course_mentorship')">
    <div class="flex flex-col h-full gap-3 px-5 py-4">
      <p class="text-orange-500 font-simplerBold text-xs">
        {{ $t('course_page.distribute_course_mentorship_note') }}
      </p>
      <SearchInput
        v-model="search"
        :placeholder="$t('course_page.search_groups')"
        :style="'neutral'"
        full
        @reset-search="search = ''"/>
      <span v-if="search" class="!text-secondary-975 text-xs">
        {{ `${$t('course_page.results_for')} "${search}"` }}
      </span>
      <div v-if="filteredGroups.length" class="flex flex-1 h-full">
        <ScrollableContainer>
          <div v-for="group in filteredGroups" :key="group.id">
            <div class="flex justify-between items-center shadow-xs p-4 flex-1">
              <p class="text-secondary-900 text-sm font-simplerBold">
                {{ group.name }}
              </p>
              <div class="flex items-center gap-2">
                <PVProgressSpinner
                  v-if="loading && group.id === selectedGroup?.id"
                  class="w-6 h-6"
                  stroke-width="4"/>
                <div
                  v-else
                  class="flex p-1.5 rounded-full items-center justify-center"
                  :class="[
                    group.distributed ? 'bg-green-100' : 'bg-secondary-100',
                    {
                      'cursor-pointer hover:!bg-[#EAEEF6]':
                        !group.distributed && !courseDistributed,
                    },
                  ]"
                  @click.stop="distributeMentorship(group)">
                  <Check v-if="group.distributed" :size="13" />
                  <PlusCircle
                    v-else
                    :size="13"
                    class="stroke-secondary-900"
                    :class="{ 'opacity-50': courseDistributed }"/>
                </div>
                <div
                  v-if="group.distributed && !loading"
                  class="p-1.5 rounded-full hover:bg-[#EAEEF6] cursor-pointer bg-[#F4F7FB]"
                  @click="e => toggleMenu(e, group)">
                  <EllipsisVertical :size="13" />
                </div>
              </div>
            </div>
          </div>
        </ScrollableContainer>
      </div>
      <div
        v-else
        class="w-full flex flex-1 flex-col justify-center items-center gap-2.5">
        <ConstructionCone />
        <text_xs>{{ $t('course_page.no_results') }}</text_xs>
      </div>
    </div>
  </PVSidebar>
  <PVMenu ref="menu" :model="items" popup>
    <template #item="{ item, props: menuProps }">
      <div
        v-ripple
        class="flex items-center gap-1"
        :class="item.class ?? []"
        v-bind="menuProps.action">
        <component :is="item.icon" :size="12" />
        {{ item.label }}
      </div>
    </template>
  </PVMenu>
</template>

<script setup lang="ts">
import PVSidebar from 'primevue/sidebar';
import PVProgressSpinner from 'primevue/progressspinner';
import { SearchInput } from '@amit/foundation';
import { ref, computed } from 'vue';
import ScrollableContainer from '/@/views/playground/partials/ScrollableContainer.vue';
import ConstructionCone from '/@/assets/construction-cone.svg?component';
import PVMenu from 'primevue/menu';
import { useAccountStore } from '/@/app/store/account';
import { useI18n } from 'vue-i18n';

import { Check, PlusCircle, EllipsisVertical, CircleX } from 'lucide-vue-next';
import {
  editGroupAddMembers,
  editGroupRemoveMembers,
} from '/@/views/playground/school-service';

const props = defineProps({
  groups: {
    type: Array,
    required: true,
  },
});

const accountStore = useAccountStore();
const { t } = useI18n();

const visible = defineModel<boolean | undefined>();
const menu = ref();
const items = [
  {
    icon: CircleX,
    label: t('course_page.cancel_registration'),
    command: () => cancelDistribution(selectedGroup.value),
    class: ['!text-red-500'],
  },
];
const search = ref('');
const loading = ref(false);
const selectedGroup: any = ref(null);
const membersParam = computed(() => {
  return {
    students: [accountStore.user.id],
  };
});

const matchesGroups = groups => {
  if (!search.value) {
    return groups;
  }
  return groups.filter(group =>
    group.name.toLowerCase().includes(search.value.toLowerCase()),
  );
};

const filteredGroups = computed(() =>
  matchesGroups(props.groups).map(group => ({
    ...group,
    distributed: group.students.some(
      student => student.id === accountStore.user.id,
    ),
  })),
);

const courseDistributed = computed(() => {
  return filteredGroups.value.some(group => group.distributed);
});

const toggleMenu = (event, group) => {
  selectedGroup.value = group;
  menu.value.toggle(event);
};

const distributeMentorship = group => {
  if (group.distributed || courseDistributed.value) {
    return;
  }

  loading.value = true;
  selectedGroup.value = group;

  const { onSuccess, onError } = editGroupAddMembers(
    group.id,
    membersParam.value,
  );
  onSuccess(() => {
    loading.value = false;
    selectedGroup.value.students?.push(accountStore.user);
    selectedGroup.value = null;
  });
  onError(() => {
    loading.value = false;
    selectedGroup.value = null;
  });
};

const cancelDistribution = group => {
  loading.value = true;

  const { onSuccess, onError } = editGroupRemoveMembers(
    group.id,
    membersParam.value,
  );
  onSuccess(() => {
    loading.value = false;
    const index = selectedGroup.value.students.findIndex(
      user => user.id === accountStore.user.id,
    );
    if (index !== -1) {
      selectedGroup.value.students.splice(index, 1);
    }
    selectedGroup.value = null;
  });
  onError(() => {
    loading.value = false;
    selectedGroup.value = null;
  });
};
</script>
